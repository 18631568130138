@font-face {
  font-family: "IBMPlexMono";
  src: url('../../assets/fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf') format('truetype');
  font-weight: light;
  font-style: normal;
}

.pics {
  position:absolute;
  object-fit: cover;
  user-select: none;
}

.worldimg1 {
  width:53vh;
  height:36vh;
  left:105vh;
  top: 38vh;
}
.worldimg2 {
  width:50vh;
  height:35vh;
  left:53vh;
  top: 63vh;
}
.worldimg3 {
  width:53vh;
  height:34vh;
  left:105vh;
  top: 2vh;
}
.worldimg4 {
  width:49vh;
  height:35vh;
  left:2vh;
  top: 63vh;
}
.worldimg5 {
  width:50vh;
  height:35vh;
  left:53vh;
  top: 26vh;
}
.worldimg6 {
  width:49vh;
  height:35vh;
  left:2vh;
  top: 26vh;
}
.worldimg7 {
  width:33vh;
  height:22vh;
  left:70vh;
  top: 2vh;
}

.worldText {
  font-family: "IBMPlexMono", sans-serif;
  width: 65vh;
  font-size: 9vh;
  padding-left: 105vh;
  padding-top: 51vh;
  color: white;
  user-select: none;
}