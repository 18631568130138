@font-face {
  font-family: "IBMPlexMono";
  src: url('../../assets/fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf') format('truetype');
  font-weight: light;
  font-style: normal;
}

.pics {
  position:absolute;
  object-fit: cover;
  user-select: none;
}

.meaningimg1 {
  width:58vh;
  height:32vh;
  top:65vh;
  left:59vh;
}

.meaningimg2 {
  width:40vh;
  height:47vh;
  top:26vh;
  left:120vh;
}
.meaningimg3 {
  width:30vh;
  height:21vh;
  top:2vh;
  left:130vh;
}
.meaningimg4 {
  width:52vh;
  height:33vh;
  top:64vh;
  left:4vh;
}
.meaningimg5 {
  width:53vh;
  height:36vh;
  top:26vh;
  left:64vh;
}
.meaningimg6 {
  width:24vh;
  height:21vh;
  top:2vh;
  left:105vh;
}
.meaningimg7 {
  width:28vh;
  height:36vh;
  top:26vh;
  left:33vh;
}
.meaningimg8 {
  width:32vh;
  height:21vh;
  top:2vh;
  left:71vh;
}
.meaningimg9 {
  width:28vh;
  height:36vh;
  top:26vh;
  left:2vh;
}

.meaningText {
  font-family: "IBMPlexMono", sans-serif;
  width: 65vh;
  font-size: 9vh;
  padding-left: 119vh;
  padding-top: 51vh;
  color: white;
  z-index: 10;
  user-select: none;
}