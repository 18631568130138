@font-face {
  font-family: "IBMPlexMono";
  src: url('../assets/fonts/IBM_Plex_Mono/IBMPlexMono-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "IBMPlexMono";
  src: url('../assets/fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf') format('truetype');
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url('../assets/fonts/Inter/static/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url('../assets/fonts/Inter/static/Inter-Light.ttf') format('truetype');
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url('../assets/fonts/Inter/static/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.fullTimeline {
  overflow-y: hidden;
  z-index: 100;
  font-family: "Inter", sans-serif;
  margin-top: calc(-280px + 25vh);
}

.dot {
  position: absolute;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 50px;
  margin-top: 298px;
  color: white;
  z-index: 101;
  font-weight: bold;
  text-shadow: 
      -0.1vh -0.1vh 0 #ff7f5f,  
      0.1vh -0.1vh 0 #ff7f5f,
      -0.1vh 0.05vh 0 #ff7f5f,
      0.1vh 0.05vh 0 #ff7f5f,
      0 0.1vh 0 #ff7f5f;
}

.line {
  position: absolute;
  border: 1px solid #ff7f5f;
  z-index: 100;
  width: 2px;
}

.bubble {
  position: absolute;
  background-color: #FFFFFF;
  padding: 5px;
  width: 150px;
  border-radius: 5px;
  border: 1px solid #ff7f5f;
  box-sizing: border-box;
  overflow-x: wrap;
  transition: transform 0.3s ease;
  z-index: 102;
}

.bubble:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.largeBubble {
  position: absolute;
  background-color: #FFFFFF;
  padding: 5px;
  width: 400px;
  border-radius: 5px;
  border: 1px solid #ff7f5f;
  box-sizing: border-box;
  overflow-x: wrap;
  transition: transform 0.3s ease;
  z-index: 102;
}

.largeBubble:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.date {
  font-weight: light;
  font-size: 8px;
  float: right;
}

.title {
  margin-top: 15px;
  font-weight: bold;
  font-size: 12px;
}

.text {
  margin-top: 5px;
  font-weight: light;
  font-size: 10px;
}

.boldedText {
  margin-top: 5px;
  font-weight: bold;
  font-size: 10px;
}

.mainLine {
  position: absolute;
  color: white;
  margin-left: 0;
  margin-top: 291px;
  font-weight: bold;
  text-shadow: 
      -0.1vh -0.1vh 0 #ff7f5f,  
      0.1vh -0.1vh 0 #ff7f5f,
      -0.1vh 0.05vh 0 #ff7f5f,
      0.1vh 0.05vh 0 #ff7f5f,
      0 0.1vh 0 #ff7f5f;
  font-size: 50px;
  z-index: 100;
}

.duckFlying {
  position: absolute;
  margin-top: 291px;
  margin-left: 1100px;
  width: 96px;
  height: 96px;
  image-rendering: pixelated;
  z-index: 101;
  animation: fly 1s infinite;
}

@keyframes fly {
  0%, 100% {
    transform: translateY(5);
  }
  50% {
    transform: translateY(-5px);
  }
}