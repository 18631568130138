@font-face {
  font-family: "IBMPlexMono";
  src: url('../../assets/fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf') format('truetype');
  font-weight: light;
  font-style: normal;
}

.pics {
  position:absolute;
  object-fit: cover;
  user-select: none;
}

.lightimg1 {
  width:21vh;
  height:32vh;
  left:67vh;
  top: 65vh;
}
.lightimg2 {
  width:16vh;
  height:22vh;
  left:91vh;
  top: 51vh;
}
.lightimg3 {
  width:49vh;
  height:71vh;
  left:110vh;
  top: 2vh;
}
.lightimg4 {
  width:62vh;
  height:32vh;
  left:2vh;
  top: 65vh;
}
.lightimg5 {
  width:13vh;
  height:12vh;
  left:75vh;
  top: 51vh;
}
.lightimg6 {
  width:70vh;
  height:37vh;
  left:2vh;
  top: 26vh;
}
.lightimg7 {
  width:32vh;
  height:47vh;
  left:75vh;
  top: 2vh;
}

.lightText {
  font-family: "IBMPlexMono", sans-serif;
  width: 80vh;
  font-size: 9vh;
  padding-left: 90vh;
  padding-top: 51vh;
  color: white;
  z-index: 10;
  user-select: none;
}