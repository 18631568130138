@font-face {
  font-family: "IBMPlexMono";
  src: url('../../assets/fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf') format('truetype');
  font-weight: light;
  font-style: normal;
}

.pics {
  position:absolute;
  object-fit: cover;
  user-select: none;
}

.yearimg1 {
  width:30vh;
  height:40vh;
  left:128vh;
  top:33vh;
}
.yearimg2 {
  width:54vh;
  height:21vh;
  left:48vh;
  top:76vh;
}
.yearimg3 {
  width:77vh;
  height:40vh;
  left:48vh;
  top:33vh;
}
.yearimg4 {
  width:48vh;
  height:27vh;
  left:110vh;
  top:2vh;
}
.yearimg5 {
  width:43vh;
  height:64vh;
  left:2vh;
  top:33vh;
}
.yearimg6 {
  width:35vh;
  height:27vh;
  left:72vh;
  top:2vh;
}

.yearText {
  font-family: "IBMPlexMono", sans-serif;
  width: 65vh;
  font-size: 9vh;
  padding-left: 105vh;
  padding-top: 51vh;
  color: white;
  user-select: none;
}