.constructionPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  gap: 20px;
  animation: constructionBounce 3s infinite;
}

.constructionText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  color: white;
  font-size: 3vw;
  line-height: 1;
  margin-left: 15px;
  text-shadow: 
    -0.1vh -0.1vh 0 #ff7f5f,  
    0.1vh -0.1vh 0 #ff7f5f,
    -0.1vh 0.1vh 0 #ff7f5f,
    0.1vh 0.1vh 0 #ff7f5f,
    0 -0.15vh 0 #ff7f5f,
    0 0.15vh 0 #ff7f5f;
}

.forklift {
  image-rendering: pixelated;
  width: 30vh;
  height: 30vh;
}